import AnchorInfoModal from '@/compnents/anchorInfo';
import { SearchListStructure } from '@/utils';
import { Form, Input, InputNumber, Modal } from 'antd';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import RedEnvelopeModel from './model';

export default observer(() => {
  const store = useMemo(() => new RedEnvelopeModel(), []);
  const { mainModel } = store;
  return (
    <>
      <SearchListStructure store={mainModel} />;
      <HandleModal store={store} />
      <AnchorInfoModal store={store.anchorInfoModalStore} />
    </>
  );
});

const HandleModal = observer(({ store }: { store: RedEnvelopeModel }) => {
  const { HandleModalModel } = store;
  return (
    <Modal
      maskClosable={false}
      open={HandleModalModel.visible}
      centered={true}
      title={HandleModalModel.title}
      onOk={() => {
        HandleModalModel.ref?.submit();
      }}
      onCancel={HandleModalModel.onCancel}
      confirmLoading={HandleModalModel.saveLoading}
    >
      <Form
        ref={HandleModalModel.setRef}
        onFinish={HandleModalModel.onFinish}
      >
        {HandleModalModel.status === 1 && (
          <Form.Item
            name="actualAmount"
            label="红包金额"
            rules={[
              {
                required: true,
                message: '请输入红包金额',
              },
            ]}
          >
            <InputNumber
              className="w-full"
              min={0.3}
              max={200}
              precision={2}
              placeholder="请输入红包金额"
            />
          </Form.Item>
        )}
        {HandleModalModel.status === 2 && (
          <Form.Item
            name="auditReason"
            label="拒绝原因"
            rules={[
              {
                required: true,
                message: '请输入拒绝原因',
              },
            ]}
          >
            <Input.TextArea
              maxLength={100}
              showCount={true}
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
});
