import AnchorInfoModel from '@/compnents/anchorInfo/model';
import {
  type BaseData,
  type IMainSubStructureModel,
  type NormalProgrammeParams,
  type PaginationData,
  RenderByPermission,
  SearchListModal,
  request,
  requireSource,
} from '@/utils';
import { Button, type FormInstance, Space, Tag, message } from 'antd';
import dayjs from 'dayjs';
import { action, observable } from 'mobx';
import type { RewardApplyRecordVO } from './type';

class HandleModalModel {
  constructor(public redModel: RedEnvelopeModel) {}
  @action public onOpen = (id: number, status: number) => {
    console.log(id, status);
    this.visible = true;
    this.status = status;
    this.id = id;
    if (status === 1) {
      this.title = '发放红包';
    }
    if (status === 2) {
      this.title = '拒绝发放红包';
    }
    // this.ref?.setFieldsValue({ id, status });
  };

  @action public onCancel = () => {
    this.visible = false;
    this.ref?.resetFields();
  };

  public setRef = (ref: FormInstance) => {
    this.ref = ref;
  };

  @observable public status = 1;
  @observable public id = 1;
  @observable public title = '发放红包';
  @observable public visible = false;
  @observable public saveLoading = false;
  public ref: FormInstance;

  @action public onFinish = async () => {
    const values = this.ref?.getFieldsValue(true);
    values.id = this.id;
    values.status = this.status;
    try {
      if (this.saveLoading) {
        return;
      }
      this.saveLoading = true;
      const res = await request<BaseData<any>>({
        url: '/pbb/platform/reward/apply/record/operation',
        method: 'POST',
        data: values,
      });
      message.success('操作成功');
      this.onCancel();
      this.redModel.mainModel.grid.onQuery();
    } catch (error) {
    } finally {
      this.saveLoading = false;
    }
  };
}

export default class RedEnvelopeModel {
  constructor() {
    this.mainModel.grid.onQuery();
  }
  @observable anchorInfoModalStore: AnchorInfoModel = new AnchorInfoModel(); //主播信息弹框

  public HandleModalModel = new HandleModalModel(this);

  readonly status = [
    { label: '未发放', value: '0', color: '' },
    { label: '已发放', value: '1', color: 'green' },
    { label: '拒绝发放', value: '2', color: 'red' },
  ];

  readonly receiptStatus = [
    { label: '未到账', value: '0', color: '' },
    { label: '已到账 ', value: '1', color: 'green' },
  ];

  public grid: IMainSubStructureModel = {
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),
    grid: {
      rowHeight: 80,
      columns: [
        {
          key: 'opreations',
          name: '操作',
          width: 150,
          formatter: ({ row }) => {
            const pageId = new URLSearchParams(window.location.search)?.get('pageId');
            return (
              <div>
                <RenderByPermission permissionId={`${pageId}_78`}>
                  <Button
                    disabled={row.status !== 0}
                    onClick={() => {
                      this.HandleModalModel.onOpen(row.id, 1);
                    }}
                    type="link"
                  >
                    发放
                  </Button>
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_79`}>
                  <Button
                    disabled={row.status !== 0}
                    onClick={() => {
                      this.HandleModalModel.onOpen(row.id, 2);
                    }}
                    type="link"
                  >
                    拒绝
                  </Button>
                </RenderByPermission>
              </div>
            );
          },
        },
        {
          key: 'name',
          name: '主播信息',
          with: 450,
          formatter: ({ row }) => {
            return (
              <div
                className={'flex flex-row items-center h-full cursor-pointer'}
                onClick={() => {
                  //弹出主播信息资料
                  this.anchorInfoModalStore.onShow(row.userId);
                }}
              >
                <img
                  src={row.avatar}
                  alt={''}
                  className={`w-13 h-13 rounded-2 flex-[0_0_auto] ${row.active === 1 ? 'border-[#D48806] border-solid border-[1px]' : ''}`}
                />
                <div className={'flex flex-col ml-4.5 flex-[0_0_auto]'}>
                  {row.active === 1 && (
                    <img
                      src={requireSource('/common/payStatus.png')}
                      alt={''}
                      className={'w-12 h-4.5 mb-1 flex-[0_0_auto]'}
                    />
                  )}
                  <div className={'flex flex-row items-center h-5 flex-[0_0_auto]'}>
                    <div className={'line-clamp-1'}>{row.name || row.nickName}</div>
                    <img
                      src={requireSource('/common/idcard.png')}
                      alt={''}
                      className={'w-4 h-4 ml-1 flex-[0_0_auto]'}
                    />
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          key: 'positionName',
          name: '职位信息',
          with: 400,
          formatter: ({ row }) => {
            return (
              <Space className="flex  flex-col  items-start justify-center h-full">
                <p className="leading-[normal]">{row.positionName}</p>
                <p className="leading-[normal]">{row.companyName}</p>
                <p className="leading-[normal]">{row.employerName}</p>
              </Space>
            );
          },
        },
        {
          key: 'applyAmount',
          name: '申请金额（元）',
        },
        {
          key: 'actualAmount',
          name: '实际金额（元）',
        },
        {
          key: 'status',
          name: '发放状态',
          formatter: ({ row }) => {
            const status = this.status.find((v) => Number(v.value) === Number(row.status));
            return status ? <Tag color={status.color}>{status.label}</Tag> : null;
          },
        },
        {
          key: 'receiptStatus',
          name: '到账状态',
          formatter: ({ row }) => {
            const status = this.receiptStatus.find((v) => Number(v.value) === Number(row.receiptStatus));
            return status ? <Tag color={status.color}>{status.label}</Tag> : null;
          },
        },
        {
          key: 'createName',
          name: '申请人',
        },
        {
          key: 'applyReason',
          name: '申请原因',
        },
        {
          key: 'refuseReason',
          name: '拒绝原因',
        },
        {
          key: 'createTime',
          name: '申请时间',
          formatter: ({ row }) => {
            return dayjs(row.createTime).format('YYYY-MM-DD HH:mm:ss');
          },
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: true,
      showPager: true,
      showPagination: true,
      showGridOrderNo: true,
      showSelectedTotal: false,
    },
    api: {
      onQuery: (params) => {
        const { filterParams, ...rest } = params;
        const postParams = {
          ...filterParams,
          ...rest,
        };

        for (const k in postParams) {
          if (Array.isArray(postParams[k])) {
            postParams[k] = postParams[k].toString();
          }
        }
        return request<PaginationData<RewardApplyRecordVO>>({
          url: '/pbb/platform/reward/apply/record/list',
          method: 'POST',
          data: { ...postParams },
        });
      },
    },
  };

  public programme: NormalProgrammeParams = {
    filterItems: [
      {
        type: 'input',
        field: 'name',
        label: '主播姓名',
        placeholder: '请输入',
      },
      {
        type: 'input',
        field: 'sysUserName',
        label: '申请人',
        placeholder: '请输入',
      },
      {
        type: 'select',
        field: 'status',
        label: '发放状态',
        placeholder: '请选择',
        data: this.status,
      },
      {
        type: 'select',
        field: 'receiptStatus',
        label: '到账状态',
        placeholder: '请选择',
        data: this.receiptStatus,
      },
    ],
  };

  public mainModel = new SearchListModal({
    programme: this.programme,
    grid: this.grid,
  });
}
